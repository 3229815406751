import React, {useState, useEffect, useCallback, useMemo, memo, useRef} from 'react'
import {
  Modal,
  TextField,
  LegacyFilters,
  ResourceList,
  ResourceItem,
  Thumbnail,
  Spinner,
  Text,
  Badge,
  Button,
  Collapsible,
} from "@shopify/polaris";
import styled from 'styled-components';
import { debounce } from "lodash";
import { ImageIcon } from "@shopify/polaris-icons";
import AdvancedSearch from './AdvancedSearch';

const FilterWrapper = styled.div`
  .Polaris-LegacyFilters-ConnectedFilterControl__MoreFiltersButtonContainer {
    display: none;
  }
  padding-bottom: 0.5rem;
  position: sticky;
  top: 0;
  z-index: 999;
  background: #FFF;
`;

const ModalSectionWrapper = styled.div`
  .Polaris-Modal-Section {
    padding: 0;
    min-height: 500px;
  }
  .disabled-item {
    opacity: 0.5;
    pointer-events: none;
  }
`

const ResourceItemContent = styled.div`
  display: flex;
  justify-content: space-between;
`

const productQuery = (queryValue, showDraft) => {
  return {
    query: `query {
      products(first: 100, query:"${showDraft ? `(status:ACTIVE OR status:DRAFT)`: `status:ACTIVE`} ${queryValue?.length > 0 ? `AND ${queryValue}` : ``}") {
        edges {
          node {
            id
            title
            handle
            totalInventory
            status
            priceRangeV2 {
              minVariantPrice {
                amount
                currencyCode
              }
              maxVariantPrice {
                amount
                currencyCode
              }
            }
            images(first: 1) {
              edges {
                node {
                  id
                  originalSrc
                  altText
                }
              }
            }
            options {
              id
              name
              position
              values
            }
            variants(first: 250) {
              edges {
                node {
                  id
                  title
                  price
                  image {
                    id
                    originalSrc
                    altText
                  }
                  compareAtPrice
                  inventoryPolicy
                  inventoryQuantity
                }
              }
            }
          }
        }
      }
    }`
  }
}

const collectionQuery = (queryValue) => {
  return {
    query: `query {
        collections(first: 100, ${queryValue?.length > 0 ? `query:"${queryValue}"`: ``}) {
          edges {
            node {
              handle
              id
              image {
                originalSrc
                altText
              }
              title
            }
          }
        }
      }
    `
  }
}

const CustomList = memo(function({resourceName, resourceType, items, selectedItems, onSelectionChange, showHeader, handleItemClick}) {
  function renderItem(item) {
    const { id, title, handle, status, priceRangeV2 } = item;
    const media = (
      resourceType == 'Product' ?
      <Thumbnail
        source={item.images.length > 0 ? item.images[0].originalSrc : ImageIcon}
        alt={item.images.length > 0 ? item.images[0].altText : ''}
        size="small"
      />
      :
      <Thumbnail
        source={item.image ? item.image.originalSrc : ImageIcon}
        alt={item.image ? item.image.altText : ''}
        size="small"
      />
    );
    return (
      <ResourceItem
        id={id}
        key={id}
        media={media}
        persistActions
        verticalAlignment="center"
        onClick={() => handleItemClick(id)}
      >
        <ResourceItemContent>
          <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
            <Text  variant="bodyMd" as="span" fontWeight="semibold">{title}</Text>
            
          </div>
          {resourceType == 'Product' && <div style={{display: "flex", justifyContent: "space-between"}}>
              {status === "DRAFT" && (
                <span style={{ marginRight: '8px' }}>
                  <Badge tone="warning">DRAFT</Badge>
                </span>
              )}
              <div style={{ minWidth: "70px", textAlign: "right" }}>
                <Text  variant="bodyMd" as="span" fontWeight="semibold">{priceRangeV2 ? (priceRangeV2?.minVariantPrice?.amount === priceRangeV2?.maxVariantPrice?.amount ? '$'+priceRangeV2?.minVariantPrice?.amount : '$'+priceRangeV2?.minVariantPrice?.amount+' - $'+priceRangeV2?.maxVariantPrice?.amount) : ""}</Text>
              </div>
            </div>   
            } 
        </ResourceItemContent>
      </ResourceItem>
    );
  }


  return (
    <ResourceList
      resourceName={resourceName}
      items={items}
      renderItem={renderItem}
      selectedItems={selectedItems}
      onSelectionChange={onSelectionChange}
      showHeader={showHeader}
      selectable
    />
  )
})

const MulProductPickerModal = (props) => {
  const { 
    shop,
    host,
    resourceType,
    open,
    token,
    initialSelectionIds,
    showVariants,
    onSelect = () => {},
    onClose = () => { console.log(`Must implement onClose in ProductPickerModal`)}, 
  } = props
  const lastPromise = useRef();
  const [loading, setLoading] = useState(true);
  const [fetchingData, setFetchingData] = useState(false);
  const [resources, setResources] = useState([]);
  const [originResources, setOriginResources] = useState([]);
  const [initialResources, setInitialResources] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [queryValue, setQueryValue] = useState(undefined);
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
  const [skuValue, setSkuValue] = useState('');
  const [idValue, setIdValue] = useState('');
  const [tagValue, setTagValue] = useState('');
  const [vendorValue, setVendorValue] = useState('');
  const [showDraft, setShowDraft] = useState(false);
  const resourceName = useMemo(() => {
    return {
      singular: 'product',
      plural: resourceType == "Product" ? 'products' : 'collections',
    }
  }, []);
 
  const filters = [
    {
      key: 'queryValue',
      label: 'query',
      filter: (
        <TextField
          label="Tagged"
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: false,
    },
  ];

  const handleClose = () => {
    setLoading(true);
    onClose();
    setQueryValue('');
    setSelectedItems([]);
    setAdvancedSearchOpen(false);
    setSkuValue('');
    setIdValue('');
    setTagValue('');
    setVendorValue('');
    setShowDraft(false);
  };

  const fetchProductsByIds = async (productIds) => {
    if (productIds.length > 0) {
      const productQuery = `
        query {
           products(first: 100, query: "${productIds.map(ids => `(id:${ids.id.split('/').slice(-1)[0]})`).join('OR')}") {
            edges {
              node {
                id
                title
                handle
                totalInventory
                status
                priceRangeV2 {
                  minVariantPrice {
                    amount
                    currencyCode
                  }
                  maxVariantPrice {
                    amount
                    currencyCode
                  }
                }
                images(first: 1) {
                  edges {
                    node {
                      id
                      originalSrc
                      altText
                    }
                  }
                }
              }
            }
          }
        }
      `;

      const collectionQuery = `
        query {
           collections(first: 100, query: "${productIds.map(ids => `(id:${ids.id.split('/').slice(-1)[0]})`).join('OR')}") {
            edges {
              node {
                handle
                id
                image {
                  originalSrc
                  altText
                }
                title
              }
            }
          }
        }
      `;
      const query = resourceType == 'Product' ? productQuery : collectionQuery
      let initResource;
      const response = await fetch("/api/graphql", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "x-shopify-shop-domain": shop,
          "x-shopify-access-token": token,
        },
        body: JSON.stringify({ query }),
      })
      const responseData = await response.json();
      if (resourceType === 'Product') {
        initResource = responseData.data.products.edges.map(edge => ({ 
          ...edge.node,
          images: edge.node.images.edges.map((imageEdge) => imageEdge.node)
        }));
      } else {
        initResource = responseData.data.collections.edges.map(edge => ({ 
          ...edge.node
        }));
      }
      setInitialResources(initResource);
    } else {
      setInitialResources([]);
    }
  };

  const fetchFunction = async (qValue, sValue, iValue, tValue, vValue, sDraft) => {
    try {
      setFetchingData(true);
      let newResources;
      let queryString = qValue || '';
      
      if (resourceType === "Product") {
        if (sValue) {
          queryString += queryString ? ` AND sku:${sValue}` : `sku:${sValue}`;
        }
        
        if (iValue) {
          queryString += queryString ? ` AND id:${iValue}` : `id:${iValue}`;
        }
        
        if (tValue) {
          queryString += queryString ? ` AND tag:${tValue}` : `tag:${tValue}`;
        }
        
        if (vValue) {
          queryString += queryString ? ` AND vendor:${vValue}` : `vendor:${vValue}`;
        }
      }

      const query = resourceType === "Product" ? productQuery(queryString, sDraft) : collectionQuery(queryString)
      const currentPromise = fetch("/api/graphql", {
        method: "POST",
        body: JSON.stringify(query),
        headers: {
          "Content-Type": "application/json",
          "x-shopify-shop-domain": shop,
          "x-shopify-access-token": token,
        },
      }).then(res => res.json())

      lastPromise.current = currentPromise;

      currentPromise.then(
        response => {
          if (currentPromise === lastPromise.current) {
            if (resourceType === 'Product') {
              newResources = response.data.products.edges.map(edge => ({ 
                ...edge.node,
                images: edge.node.images.edges.map((imageEdge) => imageEdge.node),
                variants: edge.node.variants.edges.map((variantEdge) => variantEdge.node),
              }));
            } else {
              newResources = response.data.collections.edges.map(edge => ({ 
                ...edge.node
              }));
            }
            setOriginResources(newResources);
            setResources(newResources.filter((resource) => initialSelectionIds.filter((ids) => ids.id == resource.id).length == 0));
          }
          setLoading(false);
          setFetchingData(false);
        },
        e => {
          if (currentPromise === lastPromise.current) {
            console.warn('fetch failure', e);
            setFetchingData(false);
          }
        },
      )
    } catch (error) {
      console.error('Error fetching products:', error);
      setFetchingData(false);
    }
  };

  const debouncedFetchProducts = useCallback(
    debounce((value, sValue, iValue, tValue, vValue, sDraft) => {
      fetchFunction(value, sValue, iValue, tValue, vValue, sDraft);
    }, 500), 
    [selectedItems]
  );

  const handleQueryValueChange = (value) => {
    setQueryValue(value);
    debouncedFetchProducts(value, skuValue, idValue, tagValue, vendorValue, showDraft);
  }
  
  const handleSkuValueChange = (value) => {
    setSkuValue(value);
    debouncedFetchProducts(queryValue, value, idValue, tagValue, vendorValue, showDraft);
  };

  const handleIdValueChange = (value) => {
    setIdValue(value);
    debouncedFetchProducts(queryValue, skuValue, value, tagValue, vendorValue, showDraft);
  };

  const handleTagValueChange = (value) => {
    setTagValue(value);
    debouncedFetchProducts(queryValue, skuValue, idValue, value, vendorValue, showDraft);
  };

  const handleVendorValueChange = (value) => {
    setVendorValue(value);
    debouncedFetchProducts(queryValue, skuValue, idValue, tagValue, value, showDraft);
  };
  
  const handleShowDraft = (value) => {
    setShowDraft(value);
    debouncedFetchProducts(queryValue, skuValue, idValue, tagValue, vendorValue, value);
  }

  const toggleAdvancedSearch = () => {
    setAdvancedSearchOpen(!advancedSearchOpen);
  };
 
  const handleQueryValueRemove = () => {
    setQueryValue(undefined);
    debouncedFetchProducts("", skuValue, idValue, tagValue, vendorValue, showDraft);
  }
  const handleClearAll = () => {
    handleQueryValueRemove();
  };

  const handleSelection = (selectItems) => {
    setSelectedItems(selectItems);
  };

  const handleAdd = () => {
    const totalResources = originResources.concat(initialResources);
    const fullSelectedItems = totalResources.filter((resource) => selectedItems.includes(resource.id))
    setLoading(true);
    onSelect({
      selection: fullSelectedItems
    });
    onClose();
    setQueryValue('');
    setSelectedItems([]);
  }

  const handleItemClick = useCallback((id) => {
    setSelectedItems((prevSelectedItems) => 
      prevSelectedItems.includes(id)
        ? prevSelectedItems.filter((itemId) => itemId !== id)
        : [...prevSelectedItems, id]
    )
  }, [])

  useEffect(() => {
    setSelectedItems(initialSelectionIds.map(item => item.id));
  }, [])

  useEffect(() => {
    fetchFunction(queryValue);
    fetchProductsByIds(initialSelectionIds);
  }, [initialSelectionIds]);

  function renderItem(item) {
    const { id, title, handle, status,priceRangeV2 } = item;
    const media = (
      resourceType == 'Product' ?
      <Thumbnail
        source={item.images.length > 0 ? item.images[0].originalSrc : ImageIcon}
        alt={item.images.length > 0 ? item.images[0].altText : ''}
        size="small"
      />
      :
      <Thumbnail
        source={item.image ? item.image.originalSrc : ImageIcon}
        alt={item.image ? item.image.altText : ''}
        size="small"
      />
    );
    return (
      <ResourceItem
        id={id}
        key={id}
        media={media}
        persistActions
        verticalAlignment="center"
        onClick={() => handleItemClick(id)}
      >
        <ResourceItemContent>
          <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
            <Text  variant="bodyMd" as="span" fontWeight="semibold">{title}</Text>
                  
          </div>
          {resourceType == 'Product' && <div style={{display: "flex", justifyContent: "space-between"}}>
              {status === "DRAFT" && (
                <span style={{ marginRight: '8px' }}>
                  <Badge tone="warning">DRAFT</Badge>
                </span>
              )}
              <div style={{ minWidth: "70px", textAlign: "right" }}>
                <Text  variant="bodyMd" as="span" fontWeight="semibold">{priceRangeV2 ? (priceRangeV2?.minVariantPrice?.amount === priceRangeV2?.maxVariantPrice?.amount ? '$'+priceRangeV2?.minVariantPrice?.amount : '$'+priceRangeV2?.minVariantPrice?.amount+' - $'+priceRangeV2?.maxVariantPrice?.amount) : ""}</Text>
              </div>
            </div>   
            }   
        </ResourceItemContent>
      </ResourceItem>
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title={resourceType == 'Product' ? "Add product" : "Add collection"}
      primaryAction={[
        {
          content: 'Add',
          onAction: handleAdd,
        },
      ]}
      secondaryActions={{
        content: 'Cancel',
        onAction: handleClose,
      }}
    >
      <ModalSectionWrapper>
        <Modal.Section>
        <FilterWrapper>
          {resourceType === "Product" ? (
            <AdvancedSearch
              advancedSearchOpen={advancedSearchOpen}
              toggleAdvancedSearch={toggleAdvancedSearch}
              skuValue={skuValue}
              idValue={idValue}
              tagValue={tagValue}
              vendorValue={vendorValue}
              showDraft={showDraft}
              handleSkuValueChange={handleSkuValueChange}
              handleIdValueChange={handleIdValueChange}
              handleTagValueChange={handleTagValueChange}
              handleVendorValueChange={handleVendorValueChange}
              handleShowDraft={handleShowDraft}
              legacyFilters={
                <LegacyFilters
                  queryValue={queryValue}
                  filters={filters}
                  onQueryChange={handleQueryValueChange}
                  onQueryClear={handleQueryValueRemove}
                  onClearAll={handleClearAll}
                  moreFilters={false}
                />
              }
            />
          ) : (
            <LegacyFilters
              queryValue={queryValue}
              filters={filters}
              onQueryChange={handleQueryValueChange}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleClearAll}
              moreFilters={false}
            />
          )}
        </FilterWrapper>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '160px' }}>
            <Spinner accessibilityLabel="Loading products" size="large" />
          </div>
        ) : fetchingData ? (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '160px' }}>
            <Spinner accessibilityLabel="Loading products" size="large" />
          </div>
        ) : (
          <>
           {initialResources.length > 0 &&
              <ResourceList
                resourceName={resourceName}
                items={initialResources}
                renderItem={renderItem}
                selectedItems={selectedItems}
                onSelectionChange={handleSelection}
                showHeader={false}
                selectable
              />
            }
            {resources.length > 0 && 
              <CustomList
                resourceName={resourceName}
                resourceType={resourceType}
                items={resources}
                selectedItems={selectedItems}
                onSelectionChange={handleSelection}
                showHeader={false}
                handleItemClick={handleItemClick}
              />
            }
          </>
        )}
        </Modal.Section>
      </ModalSectionWrapper>
    </Modal>
  )
}

export default MulProductPickerModal;

